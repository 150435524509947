import React from "react";
import theme from "theme";
import { Theme, Text, Section, Image, Box, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Visionary Spaces
			</title>
			<meta name={"description"} content={"Перетворіть свій простір, покращте своє життя"} />
			<meta property={"og:title"} content={"Visionary Spaces"} />
			<meta property={"og:description"} content={"Перетворіть свій простір, покращте своє життя"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-1.jpg?v=2024-06-18T11:13:28.045Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-1.jpg?v=2024-06-18T11:13:28.045Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-1.jpg?v=2024-06-18T11:13:28.045Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-1.jpg?v=2024-06-18T11:13:28.045Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-1.jpg?v=2024-06-18T11:13:28.045Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-1.jpg?v=2024-06-18T11:13:28.045Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-1.jpg?v=2024-06-18T11:13:28.045Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="icon" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
			<Override slot="icon1" />
		</Components.Header>
		<Section
			padding="150px 0 150px 0"
			background="linear-gradient(180deg,#070831 0%,transparent 100%) 0 0 no-repeat,--color-darkL1 url(https://uploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-1.jpg?v=2024-06-18T11:13:28.045Z) 0% 0% /cover repeat scroll padding-box"
			inner-width="90%"
			lazy-load
			sm-padding="80px 0 80px 0"
			quarkly-title="Team-16"
		>
			<Override slot="SectionContent" transition="color 0.2s ease 0s" />
			<Text
				margin="0px 180px 0px 180px"
				text-align="center"
				font="--headline1"
				color="--light"
				lg-margin="0px 80px 0px 80px"
				md-margin="0px 0 0px 0"
				sm-font="normal 700 42px/1.2 --fontFamily-sansHeavy"
			>
				Ласкаво просимо до Visionary Spaces
			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://uploads.quarkly.io/6671692e8d4a0c00203097b0/images/2-2.jpg?v=2024-06-18T11:13:28.042Z"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
						srcSet="https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/2-2.jpg?v=2024-06-18T11%3A13%3A28.042Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/2-2.jpg?v=2024-06-18T11%3A13%3A28.042Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/2-2.jpg?v=2024-06-18T11%3A13%3A28.042Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/2-2.jpg?v=2024-06-18T11%3A13%3A28.042Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/2-2.jpg?v=2024-06-18T11%3A13%3A28.042Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/2-2.jpg?v=2024-06-18T11%3A13%3A28.042Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/2-2.jpg?v=2024-06-18T11%3A13%3A28.042Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Ми віримо, що кожен дім має потенціал бути надзвичайним.
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					{" "}Наша команда експертів прагне перетворити ваше житлове середовище на приголомшливе відображення вашого особистого смаку та стилю. З пристрастю до досконалості та прихильністю до сталого розвитку ми пропонуємо індивідуальні рішення для дизайну та ремонту квартир, які втілюють ваше бачення в життя.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://uploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-2.jpg?v=2024-06-18T11:13:27.989Z"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
						srcSet="https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-2.jpg?v=2024-06-18T11%3A13%3A27.989Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-2.jpg?v=2024-06-18T11%3A13%3A27.989Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-2.jpg?v=2024-06-18T11%3A13%3A27.989Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-2.jpg?v=2024-06-18T11%3A13%3A27.989Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-2.jpg?v=2024-06-18T11%3A13%3A27.989Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-2.jpg?v=2024-06-18T11%3A13%3A27.989Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-2.jpg?v=2024-06-18T11%3A13%3A27.989Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				order="-1"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Про Visionary Spaces
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					У Visionary Spaces ми поєднуємо інноваційний дизайн із ретельною майстерністю. Наші послуги варіюються від повного капітального ремонту квартир до покращення конкретних приміщень, щоб оптимізувати комфорт і естетичність. Наша команда складається з досвідчених дизайнерів, архітекторів і будівельників, які працюють разом, щоб кожен проект був шедевром.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 120px 0" border-width="0 2px 0 2px" md-padding="80px 0 80px 0" quarkly-title="Statistics-9">
			<Text margin="0px 0px 0px 0px" text-align="center" font="--headline2" color="--darkL2">
				Чому обирають нас?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				margin="80px 0px 0px 0px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-margin="50px 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0 50px 0 50px"
					lg-padding="0 35px 0 35px"
					md-padding="25px 25px 25px 25px"
					md-margin="0px 0px 30px 0px"
					md-border-width="0 0 1px 0"
					md-border-style="solid"
					md-border-color="--color-lightD2"
				>
					<Text
						margin="10px 0px 20px 0px"
						text-align="center"
						font="--headline3"
						color="--primary"
						lg-font="normal 700 46px/1.2 --fontFamily-sans"
						text-transform="uppercase"
					>
						Індивідуальний дизайн
					</Text>
					<Text margin="0px 0px 0px 0px" text-align="center" font="normal 300 18px/1.5 --fontFamily-sansHelvetica">
						Кожен проект є унікальним відображенням особистості та способу життя клієнта.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="0 1px 0 1px"
					border-style="solid"
					border-color="--color-lightD2"
					padding="0 50px 0 50px"
					lg-padding="0 35px 0 35px"
					md-padding="25px 25px 25px 25px"
					md-margin="0px 0px 30px 0px"
					md-border-width="0 0 1px 0"
					md-border-style="solid"
					md-border-color="--color-lightD2"
				>
					<Text
						margin="10px 0px 20px 0px"
						text-align="center"
						font="--headline3"
						color="--primary"
						lg-font="normal 700 46px/1.2 --fontFamily-sans"
						text-transform="uppercase"
					>
						Якість майстерності
					</Text>
					<Text margin="0px 0px 0px 0px" text-align="center" font="normal 300 18px/1.5 --fontFamily-sansHelvetica">
						Ми використовуємо лише найкращі матеріали та кваліфіковану працю, щоб забезпечити тривалу якість.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0 50px 0 50px"
					lg-padding="0 35px 0 35px"
					md-padding="25px 25px 25px 25px"
					md-margin="0px 0px 30px 0px"
					md-border-width="0 0 1px 0"
					md-border-style="solid"
					md-border-color="--color-lightD2"
				>
					<Text
						margin="10px 0px 20px 0px"
						text-align="center"
						font="--headline3"
						color="--primary"
						lg-font="normal 700 46px/1.2 --fontFamily-sans"
						text-transform="uppercase"
					>
						Прозоре ціноутворення
					</Text>
					<Text margin="0px 0px 0px 0px" text-align="center" font="normal 300 18px/1.5 --fontFamily-sansHelvetica">
						Жодних прихованих комісій. Що ви бачите, те й платите.
					</Text>
				</Box>
			</Box>
			<Button
				margin="3rem auto 0px auto"
				display="flex"
				max-width="200px"
				align-items="center"
				justify-content="center"
				align-content="center"
				type="link"
				text-decoration-line="initial"
				background="--color-red"
				border-radius="30px"
				width="100%"
			>
				Наші послуги
			</Button>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://uploads.quarkly.io/6671692e8d4a0c00203097b0/images/2-4.jpg?v=2024-06-18T11:13:27.982Z"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
						srcSet="https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/2-4.jpg?v=2024-06-18T11%3A13%3A27.982Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/2-4.jpg?v=2024-06-18T11%3A13%3A27.982Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/2-4.jpg?v=2024-06-18T11%3A13%3A27.982Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/2-4.jpg?v=2024-06-18T11%3A13%3A27.982Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/2-4.jpg?v=2024-06-18T11%3A13%3A27.982Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/2-4.jpg?v=2024-06-18T11%3A13%3A27.982Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6671692e8d4a0c00203097b0/images/2-4.jpg?v=2024-06-18T11%3A13%3A27.982Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Наше прагнення до досконалості
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Вибір Visionary Spaces означає безпроблемну реконструкцію. Ми надаємо пріоритет чіткій комунікації, прозорості та пунктуальності, щоб зробити процес ремонту максимально плавним та приємним. Наша відданість якості та увага до деталей очевидні в кожному куточку нашої роботи, забезпечуючи результати, які не тільки відповідають, але й перевищують ваші очікування.
				</Text>
			</Box>
		</Section>
		<Components.Contacts>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="link" />
			<Override slot="text3" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text1" />
		</Components.Contacts>
		<Components.Footer>
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link" />
			<Override slot="link3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});